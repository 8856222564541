var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"7d9eae8"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { Offline as OfflineIntegration } from "@sentry/integrations";

const SENTRY_DSN =
    process.env.NEXT_PUBLIC_ENJIN_SENTRY_DSN ||
    process.env.SENTRY_DSN ||
    process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENVIRONMENT =
    process.env.NEXT_PUBLIC_ENJIN_SENTRY_ENVIRONMENT || "unknown";

const tracePropagationTargets = [];
if (process.env.NEXT_PUBLIC_ENJIN_KONSOL_PROKSI_API_URL) {
    const apiURL = new URL(process.env.NEXT_PUBLIC_ENJIN_KONSOL_PROKSI_API_URL);
    tracePropagationTargets.push(apiURL.hostname);
}

Sentry.init({
    dsn: SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.05,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    environment: SENTRY_ENVIRONMENT,
    integrations: [
        new OfflineIntegration(),
        new Sentry.BrowserTracing({
            tracePropagationTargets,
            // Keep transaction alive long enough for spans from API requests
            // to go through.
            idleTimeout: 3000,
        }),
    ],
    ignoreErrors: [
        "QuotaExceededError: Quota exceeded.",
        "ResizeObserver loop limit exceeded",
        "ResizeObserver loop completed with undelivered notifications.",
    ],
    denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
});
